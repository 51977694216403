import { useEffect, useState } from "react";
import Lottie from "react-lottie-player";
import { useSpring } from "react-spring";

import lottieJson from "@Components/pages/common/LandingHero/scroll-down.json";
import { useAnalytics } from "@Hooks/useAnalytics";

import { ProductCard } from "./ProductCard";
import { AnimatedContainer, Arrow, ArrowWrapper, Wrapper } from "./styles";
import type { Card } from "./types";

export type Props = {
  content: Card[];
  i18nNamespace: string;
  arrowAnchor?: string;
  renderDelay?: number;
  testId: string;
};

export const HeroProductWidget = ({
  i18nNamespace,
  content,
  arrowAnchor = "#trustpilot",
  renderDelay = 3000,
  testId,
}: Props) => {
  const segmentAnalytics = useAnalytics();
  const [animateIn, setAnimateIn] = useState(false);
  const [hideWidget, setHideWidget] = useState(false);

  const animate = useSpring({
    bottom: hideWidget || !animateIn ? -300 : 0,
    config: { mass: 0.5, tension: 220 },
  });

  const handleScroll = () => {
    if (window.scrollY > 400) {
      setHideWidget(true);
    } else if (window.scrollY <= 400) {
      setHideWidget(false);
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setAnimateIn(true);
    }, renderDelay);
    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
      clearTimeout(timeout);
    };
  }, [renderDelay]);

  const handleArrowClick = () => document.querySelector(arrowAnchor)?.scrollIntoView({ behavior: "smooth" });

  const fireSegmentClickEvent = (ctaName: string, product: string) => {
    if (segmentAnalytics) {
      segmentAnalytics?.track("cta_clicked", {
        category: "homepage",
        cta_name: ctaName,
        product,
      });
    }
  };

  return (
    <AnimatedContainer data-testid={testId} style={animate}>
      <ArrowWrapper>
        <Arrow onClick={handleArrowClick}>
          <Lottie animationData={lottieJson} loop play style={{ height: 40, width: 40 }} />
        </Arrow>
      </ArrowWrapper>
      <Wrapper tabIndex={1}>
        {content.map((card) => (
          <ProductCard
            card={card}
            i18nNamespace={i18nNamespace}
            key={card.title}
            onClick={() => fireSegmentClickEvent("homepage_product_widget", card.title)}
          />
        ))}
      </Wrapper>
    </AnimatedContainer>
  );
};
