import { useRouter } from "next/compat/router";
import useTranslation from "next-translate/useTranslation";
import { Button, H5, Paragraph, Spacing } from "@boxt/design-system";

import { ReactComponent as Error } from "@Images/icons/calendar-error.svg";

import { Container, Content } from "./styles";

const Calendar = () => {
  const { t } = useTranslation("common");
  const router = useRouter();
  return (
    <Spacing mh={{ md: 0, sm: 2 }} mt={3}>
      <Container>
        <Content mb={{ md: 5, sm: 3 }} mh={2} mt={{ md: 4, sm: 2 }}>
          <Error />
          <H5 top={2}> {t("errors.invalid-date.title")} </H5>
          <Paragraph align="center" top={2}>
            {t("errors.invalid-date.note")}
          </Paragraph>
          <Spacing mb={{ lg: 5, md: 4, sm: 3 }} mt={2}>
            <Button
              boxtTheme="jade"
              isFullWidth
              onClick={() => {
                router?.reload();
              }}
            >
              {t("errors.invalid-date.button")}
            </Button>
          </Spacing>
        </Content>
      </Container>
    </Spacing>
  );
};

export default Calendar;
