import { useEffect } from "react";
import { useRouter } from "next/router";
import { useFsFlag } from "@flagship.io/react-sdk";
import { destroyCookie, setCookie } from "nookies";

import { BoilerQueryValue, PLP_FILTER_COOKIE_KEY, PlpFilterCookieValue } from "./types";

export const useSetFilterCookie = () => {
  const isManufacturerFilterFlagEnabled = useFsFlag("plp_manufacturer_filter_logic", false).getValue();
  const { query } = useRouter();
  const queryToCheck = query.path ?? Object.keys(query);

  useEffect(() => {
    const setFilterCookie = (cookieValue: PlpFilterCookieValue) => {
      setCookie(null, PLP_FILTER_COOKIE_KEY, cookieValue, {
        path: "/",
        sameSite: true,
      });
    };

    if (queryToCheck.includes(BoilerQueryValue.CheapBoilers)) {
      setFilterCookie(PlpFilterCookieValue.LowestPrice);
      return;
    }
    if (queryToCheck.includes(BoilerQueryValue.WorcesterBosch)) {
      setFilterCookie(PlpFilterCookieValue.WorcesterBosch);
      return;
    }
    if (isManufacturerFilterFlagEnabled) {
      if (queryToCheck.includes(BoilerQueryValue.Vaillant)) {
        setFilterCookie(PlpFilterCookieValue.Vaillant);
        return;
      }
      if (queryToCheck.includes(BoilerQueryValue.Navien)) {
        setFilterCookie(PlpFilterCookieValue.Navien);
        return;
      }
      if (queryToCheck.includes(BoilerQueryValue.Baxi)) {
        setFilterCookie(PlpFilterCookieValue.Baxi);
        return;
      }
    }

    destroyCookie(null, PLP_FILTER_COOKIE_KEY);
  }, [isManufacturerFilterFlagEnabled, queryToCheck]);
};
