"use client";

import styled from "styled-components";
import { breakpoint, colors, includeSpacing, rem } from "@boxt/design-system";

import { BACKGROUND_COLORS } from "@Collections/themeColors";

export const Container = styled.div<{ $bg?: string }>`
  height: ${rem(30)};
  position: relative;
  display: flex;
  z-index: 15;
  justify-content: center;
  align-items: center;
  background-color: ${({ $bg }) => ($bg ? BACKGROUND_COLORS[$bg] : colors.platinum.lighter)};

  ${breakpoint("md")} {
    height: ${rem(33)};
  }
`;

export const ItemContainer = styled.div`
  display: flex;
  flex: 1;
  flex-basis: max-content;
  justify-content: center;
  align-items: center;
`;
export const Slide = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MDWrapper = styled.div`
  display: none;
  width: 100%;
  ${breakpoint("md")} {
    display: flex;
  }
`;
export const SMWrapper = styled.div`
  width: 100%;
  ${breakpoint("md")} {
    display: none;
  }
`;
export const IconWrapper = styled.div`
  width: ${rem(19)};
  height: ${rem(19)};
  ${includeSpacing({ mr: 1 })}
`;
