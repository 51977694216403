import { animated } from "react-spring";
import Link from "next/link";
import styled from "styled-components";
import { breakpoint, colors, rem } from "@boxt/design-system";

export const AnimatedContainer = styled(animated.div)<any>`
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 10;
  width: 100vw;
`;

export const Wrapper = styled.nav`
  height: 100%;
  display: flex;
  justify-content: start;
  align-items: stretch;
  overflow-x: scroll;
  height: ${rem(130)};
  background-color: #e9ede4;

  ${breakpoint("md")} {
    height: ${rem(145)};
    justify-content: space-evenly;
  }

  ${breakpoint("lg")} {
    height: ${rem(150)};
  }

  ${breakpoint("lg")} {
    height: ${rem(150)};
    justify-content: center;
  }
`;

export const ArrowWrapper = styled.div`
  position: relative;
  width: 100vw;
`;

export const Arrow = styled.a`
  position: absolute;
  bottom: ${rem(8)};
  right: calc(50% - ${rem(20)});
  z-index: 10;
  cursor: pointer;
`;

export const LinkWrapper = styled(Link)`
  height: 100%;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CardContainer = styled.div`
  width: ${rem(124)};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  ${breakpoint("md")} {
    width: ${rem(148)};
  }

  ${breakpoint("lg")} {
    width: ${rem(192)};
    &:active,
    &:hover {
      background-color: ${colors.white};
    }
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: ${rem(88)};
  width: ${rem(88)};
`;
