import { useRouter } from "next/compat/router";
import Trans from "next-translate/Trans";
import useTranslation from "next-translate/useTranslation";
import { Button, H3, Paragraph, Spacing } from "@boxt/design-system";

import CONTACT_DETAILS from "@Collections/contactDetails";
import useChat from "@Hooks/useChat";
import { ReactComponent as Error } from "@Images/error.svg";
import { ReactComponent as ChatIcon } from "@Images/icons/chat.svg";

import { Container, Content, Text, Wrapper } from "./styles";

export const errorType = {
  notWorking: "not-working",
  wentWrong: "went-wrong",
  wentWrongStartAgain: "went-wrong-start-again",
};

export type Props = {
  error: string;
  onStartAgain?: () => void;
};

const ErrorPage = ({ error, onStartAgain }: Props) => {
  const { t } = useTranslation("common");
  const router = useRouter();
  const { openChat } = useChat();

  const handleOnClick = () => {
    switch (error) {
      case errorType.wentWrong:
        return router?.reload();
      case errorType.wentWrongStartAgain:
        return onStartAgain?.();
      default:
        return openChat();
    }
  };

  return (
    <Container>
      <Spacing mt={5} />
      <Wrapper>
        <Spacing mt={6} />
        <Error />
        <H3 top={4}>{t(`errors.page-error.title.${error}`)}</H3>
        <Paragraph align="center" size="large" top={2}>
          {t(`errors.page-error.note.${error}`)}
        </Paragraph>
        <Content>
          <Text>
            <Trans
              components={{
                livechat: (
                  <Button
                    boxtTheme="coral"
                    onClick={() => openChat()}
                    rel="noopener noreferrer"
                    skin="link"
                    target="_blank"
                  />
                ),
                number: (
                  <Button boxtTheme="coral" href={`tel://${CONTACT_DETAILS.PHONE.replace(/\s/g, "")}`} skin="link" />
                ),
                p: <Paragraph align={{ md: "left", sm: "center" }} />,
                s: <strong />,
              }}
              i18nKey="common:errors.page-error.content.availability"
            />
            <Paragraph align={{ md: "left", sm: "center" }}>{t("errors.page-error.content.contact")}</Paragraph>
          </Text>
          <Spacing mb={{ md: 0, sm: 3 }}>
            <Button
              boxtTheme="jade"
              onClick={handleOnClick}
              {...(error === errorType.notWorking && {
                icon: {
                  afix: "left",
                  size: "medium",
                  svg: <ChatIcon />,
                },
              })}
            >
              {t(`errors.page-error.button.${error}`)}
            </Button>
          </Spacing>
        </Content>
      </Wrapper>
    </Container>
  );
};

export default ErrorPage;
