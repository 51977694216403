import React from "react";
import { LoadingOverlay } from "@boxt/design-system";

import { IconWrapper, LoadingWrapper } from "@Components/pages/common/Loader/styles";

type Props = {
  innerIcon?: React.ReactElement;
  className?: string;
};

const Loader = ({ innerIcon, className }: Props) => (
  <LoadingWrapper className={className} data-testid="loader">
    <LoadingOverlay innerIcon={innerIcon && <IconWrapper>{innerIcon}</IconWrapper>} />
  </LoadingWrapper>
);

export default Loader;
