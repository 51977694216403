import type { ReactNode } from "react";

import { PageContainer, StyledGrid } from "@Components/pages/content/components/ContentPage/styles";

type Props = {
  renderedHero: ReactNode[];
  renderedModules: ReactNode[];
};

const ContentPage = ({ renderedHero, renderedModules }: Props) => {
  return (
    <PageContainer>
      {renderedHero}
      <StyledGrid>{renderedModules}</StyledGrid>
    </PageContainer>
  );
};

export default ContentPage;
