"use client";

import styled from "styled-components";
import tinyColor from "tinycolor2";
import { breakpoint, colors, rem, Spacing } from "@boxt/design-system";

export const Container = styled.div`
  width: 100vw;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
  color: ${colors.slate.normal};
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
`;

export const Content = styled(Spacing)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  border: 1px solid ${tinyColor(colors.slate.lighter).setAlpha(0.2).toRgbString()};
  min-width: ${rem(350)};
  ${breakpoint("md")} {
    min-width: ${rem(500)};
  }
`;

export const ButtonWrapper = styled(Spacing)`
  width: 100%;
  padding: 0 ${rem(10)} 0 ${rem(10)};
`;
