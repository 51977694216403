"use client";

import styled from "styled-components";
import tinyColor from "tinycolor2";
import { colors, rem, Spacing } from "@boxt/design-system";

export const Container = styled.div`
  max-width: ${rem(500)};
  color: ${colors.slate.normal};
  border-radius: 12px;
  border: 1px solid ${tinyColor(colors.slate.lighter).setAlpha(0.2).toRgbString()};
  margin: 0 auto;
`;

export const Content = styled(Spacing)`
  text-align: center;
`;
