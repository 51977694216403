"use client";

import styled from "styled-components";
import { colors, Grid } from "@boxt/design-system";

export const PageContainer = styled.div`
  background-color: ${colors.white};
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
`;

export const StyledGrid = styled(Grid)`
  width: 100%;
`;
