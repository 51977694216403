import { useRouter } from "next/compat/router";
import useTranslation from "next-translate/useTranslation";
import { Button, H5, Paragraph, Spacing } from "@boxt/design-system";

import { ReactComponent as Error } from "@Images/error.svg";

import { ButtonWrapper, Container, Content } from "./styles";

const Calendar = () => {
  const { t } = useTranslation("checkout");
  const router = useRouter();
  return (
    <Container>
      <Content mt={4}>
        <Spacing mt={4} />
        <Error />
        <H5 top={2}> {t("errors.calendar.title")} </H5>
        <Paragraph align="center" top={2}>
          {t("errors.calendar.note")}
        </Paragraph>
        <ButtonWrapper mb={{ lg: 5, md: 4, sm: 3 }} mt={2}>
          <Button
            boxtTheme="jade"
            isFullWidth
            onClick={() => {
              router?.reload();
            }}
          >
            {t("errors.calendar.button")}
          </Button>
        </ButtonWrapper>
      </Content>
    </Container>
  );
};

export default Calendar;
