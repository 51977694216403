export const PLP_FILTER_COOKIE_KEY = "plp-filter-personalisation";

export enum PlpFilterCookieValue {
  LowestPrice = "lowest-price-sort",
  WorcesterBosch = "worcester-bosch",
  Vaillant = "vaillant",
  Navien = "navien",
  Baxi = "baxi",
}

export enum BoilerQueryValue {
  CheapBoilers = "cheap-boilers",
  WorcesterBosch = "worcester-bosch",
  Vaillant = "vaillant",
  Navien = "navien",
  Baxi = "baxi",
}
