"use client";

import styled from "styled-components";
import { rem } from "@boxt/design-system";

export const LoadingWrapper = styled.div`
  flex: 1;
  position: relative;
  width: 100%;
  height: ${rem(600)};
`;

export const IconWrapper = styled.div`
  width: ${rem(54.5)};
  height: ${rem(59.5)};
`;
